<template>
  <div ref="modalContainer"></div>
  <a-card class="content-wrapper">
    <template #title>
      <CardHeader></CardHeader>
    </template>
    <div class="body-content">
      <a-form layout="inline">
        <a-form-item label="角色名称">
          <a-input
            v-model:value="params.name"
            placeholder="角色名称"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="doQuery">
            <template #icon>
              <SearchOutlined />
            </template>
            查询
          </a-button>
        </a-form-item>
        <a-form-item>
          <a-button class="btn-success" @click="showAddModal">
            <template #icon>
              <PlusCircleOutlined />
            </template>
            添加
          </a-button>
        </a-form-item>
      </a-form>
      <div style="padding: 4px 0">
        <a-table
          bordered
          :loading="loading"
          row-key="id"
          :pagination="false"
          :columns="columns"
          :data-source="dataList.list"
          :scroll="{ y: 500 }"
        >
          <template #operation="{ record }">
            <div v-if="record.id !== '1'">
              <TipButton
                perm="sys:role:edit"
                btn-class="btn-success"
                title="编辑"
                @btnClick="showEditModal(record.id)"
              >
                <template #default>
                  <EditOutlined />
                </template>
              </TipButton>
              <TipButton
                perm="sys:role:del"
                btn-class="btn-danger"
                title="删除"
                @btnClick="delItem(record.id)"
              >
                <template #default>
                  <DeleteOutlined />
                </template>
              </TipButton>
              <TipButton
                perm="sys:role:auth"
                type="primary"
                title="权限分配"
                @click="showAuthModal(record.id)"
              >
                <template #default>
                  <KeyOutlined />
                </template>
              </TipButton>
            </div>
          </template>
        </a-table>
      </div>
      <a-pagination
        v-model:current="page"
        v-model:page-size="pageSize"
        :total="total"
        :show-total="total => `${total}条数据`"
        @change="currentChange"
      ></a-pagination>
    </div>
  </a-card>
  <add
    v-if="addShow"
    :modal-container="$refs.modalContainer"
    @close="hideAddModal"
  ></add>
  <edit
    v-if="editShow"
    :modal-container="$refs.modalContainer"
    :id="currentId"
    @close="hideEditModal"
  ></edit>
  <auth
    v-if="authShow"
    :modal-container="$refs.modalContainer"
    :id="currentId"
    @close="authShow = false"
  ></auth>
</template>

<script>
import CardHeader from "@/components/CardHeader";
import useSysRole from "@/hooks/system/useSysRole";
import usePagination from "@/hooks/usePagination";
import useMessage from "@/hooks/useMessage";
import { onMounted, ref } from "vue";
import useAddExit from "@/hooks/useAddExit";
import Add from "@/views/System/Role/components/Add";
import Edit from "@/views/System/Role/components/Edit";
import { deleteRole } from "@/api/sys/sysRoleApi";
import TipButton from "@/components/TipButton";
import Auth from "@/views/System/User/components/Auth";

export default {
  name: "index",
  components: { Auth, Edit, Add, CardHeader, TipButton },
  setup() {
    const { dataList, columns, loading, params, total, loadData } =
      useSysRole();
    const { page, pageSize, currentChange, doQuery, doRefresh } =
      usePagination(loadData);
    const { showLoading, closeLoading, showConfirm, showErr, showTipMessage } =
      useMessage();
    const authShow = ref(false);
    const {
      currentId,
      addShow,
      editShow,
      showEditModal,
      hideEditModal,
      showAddModal,
      hideAddModal,
    } = useAddExit(doQuery);

    const showAuthModal = id => {
      currentId.value = id;
      authShow.value = true;
    };

    const delItem = async id => {
      let r = await showConfirm("如果有用户绑定角色将无法删除，是否继续?");
      if (r) {
        await showLoading("正在删除，请稍后...");
        try {
          await deleteRole(id);
          await closeLoading();
          await showTipMessage("删除成功");
          doQuery();
        } catch (e) {
          await closeLoading();
          await showErr(e);
        }
      }
    };

    onMounted(() => {
      loadData(page.value, pageSize.value);
    });
    return {
      params,
      dataList,
      columns,
      loading,
      total,

      delItem,

      page,
      pageSize,
      currentChange,
      doQuery,
      doRefresh,

      currentId,
      addShow,
      editShow,
      showEditModal,
      hideEditModal,
      showAddModal,
      hideAddModal,

      authShow,
      showAuthModal,
    };
  },
};
</script>

<style scoped></style>
